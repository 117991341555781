<template>
  <div class="detail">
    <div class="title">{{ detail.title }}</div>
    <div
      v-html="detail.content"
      style="font-size: 16px; line-height: 25px"
    ></div>
    <div class="time">{{ detail.created_at }}</div>
    <div class="back">
      <router-link to="/notice">Back to notice>></router-link>
    </div>
  </div>
</template>

<script>
import { noticedetail } from '../../api/user'
export default {
  data() {
    return {
      detail: {},
      id: ''
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getdetail()
  },
  methods: {
    async getdetail() {
      const data = await noticedetail({
        id: this.id
      })
      this.detail = data
    }
  }
}
</script>

<style lang="less" scoped>
.detail {
  width: 80%;
  margin: 0 auto;
  .title {
    font-size: 30px;
    font-weight: bold;
    padding: 20px 0;
    text-align: center;
  }
  /deep/p {
    font-size: 16px;
    padding: 10px 0;
  }
  /deep/img {
    width: 100%;
  }
  .time {
    text-align: right;
    font-size: 16px;
    color: #909399;
  }
  .back {
    margin-top: 10px;
    text-align: right;
    a {
      color: #409eff;
      font-size: 14px;
    }
  }
}
</style>
